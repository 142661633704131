import React, { useEffect } from "react"
import { Row, Col } from "react-flexbox-grid"
import { navigate } from "gatsby"

import Layout from "../components/Layout"
import HelmetWrapper from "../components/HelmetWrapper"
import Carousel from "../components/Carousel"
import CalloutBlock from "../components/CalloutBlock"
import callPopup from "../helper/emitter"

import medicineIcon from "../assets/images/cta-icon1.png"
import scienceIcon from "../assets/images/cta-icon2.png"
import cultureIcon from "../assets/images/cta-icon3.png"
import pressIcon from "../assets/images/cta-icon4.png"

import peopleBg from "../assets/images/cta-bg1.png"
import scienceBg from "../assets/images/cta-bg2.png"
import cultureBg from "../assets/images/cta-bg3.png"
import pressBg from "../assets/images/cta-bg4.png"
import arrow from "../assets/images/chevron-circle-right-solid.svg"

import "./index.scss"

const pageTitle ="Harmony Biosciences | Patients Are At The Heart Of Everything We Do"
const pageDescription = "Harmony Biosciences is a growing CNS-focused biopharmaceutical company dedicated to bringing innovative medicines to people living with rare neurological disease."
const pageKeywords = "rare diseases - 12,100"

const IndexPage = () => {
  const aboutUsBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga("send", "event", "Button", "click", "About Harmony Button")
    }
    navigate("/about-us")
  }
  useEffect(() => {
    setTimeout(() => {
      callPopup.emit("onPopupEvent")
    }, 500)
  }, [])



  return (
    <Layout jobCode="US-HAR-2200051/Jul 2022">
      <div id="home">
        <HelmetWrapper
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          pageKeywords={pageKeywords}
          slug={`/`}
        />
        <Carousel />
        <Row className={"callout-row"}>
          <Col md={3} xs={12}>
            <CalloutBlock
              className={"about"}
              backgroundColor="#f5e1a4"
              icon={medicineIcon}
              bg={peopleBg}
              link="/about-us"
            >
              <h2>
                ABOUT HARMONY <img className="callout-arrow" src={arrow} />
              </h2>
              <p style={{ lineHeight: 1 }}>
                We specialize in developing
                <br />
                and delivering new treatments to help people
                <br /> living with rare
                <br />
                neurological diseases
              </p>
              <div className="copy-container">
                <h2>
                  ABOUT HARMONY <img className="callout-arrow" src={arrow} />
                </h2>
              </div>
            </CalloutBlock>
          </Col>
          <Col md={3} xs={12}>
            <CalloutBlock
              className={"science"}
              backgroundColor="#e1edd1"
              icon={scienceIcon}
              bg={scienceBg}
              link={"/science"}
            >
              <h2>
                PIPELINE <img className="callout-arrow" src={arrow} />
              </h2>
              <p style={{ lineHeight: 1 }}>
                Translating innovative
                <br />
                science into therapeutic possibilities
              </p>
              <div className="copy-container">
                <h2>
                  PIPELINE <img className="callout-arrow" src={arrow} />
                </h2>
              </div>
            </CalloutBlock>
          </Col>
          <Col md={3} xs={12}>
            <CalloutBlock
              className={"culture"}
              backgroundColor="#c4d5ea"
              icon={cultureIcon}
              bg={cultureBg}
              link={"/about-us/careers"}
            >
              <h2>
                CAREERS <img className="callout-arrow" src={arrow} />
              </h2>
              <p style={{ lineHeight: 1 }}>
                View career opportunities
                <br />
                and apply for open positions
              </p>
              <div className="copy-container">
                <h2>
                  CAREERS <img className="callout-arrow" src={arrow} />
                </h2>
              </div>
            </CalloutBlock>
          </Col>
          <Col md={3} xs={12}>
            <CalloutBlock
              className={"press"}
              backgroundColor="#ddddde"
              icon={pressIcon}
              bg={pressBg}
              link={"/newsroom"}
            >
              <h2>
                PRESS RELEASES <img className="callout-arrow" src={arrow} />
              </h2>
              <p style={{ lineHeight: 1 }}>
                As we develop new <br /> advances, we share <br /> our progress
              </p>
              <div className="copy-container ">
                <h2>
                  PRESS RELEASES <img className="callout-arrow" src={arrow} />
                </h2>
              </div>
            </CalloutBlock>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage
