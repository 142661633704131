import React from "react"
import { Fade } from "react-slideshow-image"

import img1 from "../../assets/images/home-hero6.png"
import img2 from "../../assets/images/home-hero2.png"
import img3 from "../../assets/images/home-hero3.png"
import img4 from "../../assets/images/home-hero4.png"
import img5 from "../../assets/images/home-hero5.png"

import styles from "react-slideshow-image/dist/styles.css"
import "./index.scss"

const fadeProperties = {
  duration: 30000000,
  transitionDuration: 0,
  infinite: true,
  indicators: false,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    console.log(`fade transition from ${oldIndex} to ${newIndex}`)
  },
}

//For customizations
//https://github.com/femioladeji/react-slideshow#readme

const HBCarousel = ({ className, bg, children }) => {
  const copyBlock = (
    <div className="copy-block-container">
      <h1>
        Patients are <br /> at the heart
      </h1>
      <p>
        <em>of everything we do</em>
      </p>
      <p className="small">
        "We believe innovative science can <br/>translate into therapeutic
        possibilities"
      </p>
    </div>
  )

  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
        <div className="each-fade hb-slide6">
          {copyBlock}
          <div className="image-container">
            <img src={img1} />
          </div>
        </div>


      </Fade>
    </div>
  )
}

export default HBCarousel
